@import "components/ColorsVars.scss";

.view {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}

.Components {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 20px;
  max-width: 500px;
  overflow: hidden;
  width: 100%;

  header {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 10px;
    position: relative;

    &:before {
      background: $gradientBlue;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    h1 {
      color: $blue;
      font-size: 24px;
      margin: 0;
      padding: 20px 20px 0;
    }

    h2 {
      color: $paragraphColor;
      font-size: 20px;
      margin: 0;
      padding: 0 20px 10px;
    }
  }

  main {
    display: block;
    padding: 20px;

    footer {
      background: #dedede;
      flex-wrap: wrap;
      margin: 20px -20px -20px;
      padding: 10px 20px;

      p {
        color: $paragraphColor;
        flex-grow: 1;
        font-size: 15px;
        font-weight: 500;
        margin: 0;
      }
    }

    small {
      color: $smallTextColor;
      display: block;
      margin-top: 20px;
    }
  }
}
