.view {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}

.box {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 32px;
  max-width: 440px;
  width: calc(100% - 40px);
  min-width: 320px;
  min-height: 338px;

  .alert {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .logo {
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 10px;
  }

  p {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    margin: 10px 0;

    &.info {
      font-style: italic;
      color: #69798c;
    }
  }

  .comment {
    color: #69798c;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
  }

  .loginWithIcon {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.6);
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    margin-bottom: 1rem;

    input {
      color: #00000099;
      height: 36px;
      background-color: transparent;
      border: 0;
      margin: 0;
    }

    .logo {
      color: #0eaf84;
    }
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 6px 10px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.6);
    height: 36px;
    outline: none;
    border-radius: 0;
    background-color: transparent;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 0;
  }

  input[type="submit"],
  button {
    display: block;
    color: #fff;
    background-color: #0067b8;
    display: block;
    min-height: 32px;
    border: none;
    min-width: 108px;
    line-height: normal;
    cursor: pointer;
    padding: 0 20px;

    &:disabled {
      background-color: #5a5a5a;
      cursor: default;

      &:read-only {
        opacity: 0.3;
      }
    }
  }

  .actions {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }

  div[data-tip] {
    display: inline-block;
  }

  .link {
    display: inline-block;
    color: #0067b8;
    font-size: 0.8125rem;
    cursor: pointer;
    background: none;
    padding: 0;
    text-decoration: none;

    &:hover {
      color: #666;
      text-decoration: underline;
    }
  }

  .bottom {
    position: absolute;
    left: 32px;
    bottom: 38px;

    .link {
      font-size: 12px;
    }
  }

  .formGroup {
    label {
      color: #69798c;
      font-size: 0.75rem;
      font-weight: bold;
      > strong {
        color: #db0b00;
      }
    }
    margin-bottom: 1rem;

    .error {
      color: #db0b00;
      font-size: 0.75rem;
      margin-top: 0.375rem;
    }

    .passwordChecks {
      margin-left: 0.25rem;
      .passwordCheck {
        display: flex;
        align-items: center;
        margin-top: 0.25rem;
        > .icon {
          width: 0.75rem;
          height: 0.75rem;
          display: flex;
          align-items: center;
          &.iconCheck {
            color: #0eaf84;
          }
          &.iconCircle {
            border: 2px solid #69798c33;
            border-radius: 50%;
            margin-bottom: 0.125rem;
          }
        }
        .passwordCheckText {
          margin-left: 0.5rem;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.passwordLogin {
  position: absolute;
  bottom: 10px;
  color: #ccc;
  font-size: 10px;
  text-decoration: none;
}
