/* https://create-react-app.dev/docs/adding-css-reset */
@import-normalize;

@import "./mixins.scss";

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.4;
  margin: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
}

button,
input,
select,
textarea {
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
}

img {
  display: block;
  object-fit: contain;
  user-select: none;
}

img:not([src]) {
  visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
button {
  font: inherit;
}

a,
a *,
button,
button *,
input[type="submit"],
input[type="submit"] * {
  cursor: pointer;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s;
}
