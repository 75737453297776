@import "components/ColorsVars.scss";

.view {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}

.LogIn {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 20px;
  max-width: 100%;
  overflow: hidden;
  width: 450px;

  header {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    position: relative;

    &:before {
      background: $gradientBlue;
      content: "";
      display: block;
      height: 10px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    svg {
      fill: #666 !important;
      height: 75px;
      padding: 30px 0 0;
    }

    h1 {
      color: $blue;
      font-size: 24px;
      margin: 0;
      padding: 10px 0 30px;
      text-align: center;
    }
  }

  main {
    display: block;
    padding: 30px;

    footer {
      align-items: center;
      background: #f1f1f1;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      margin: 30px -30px -30px;
      padding: 15px 20px;

      p {
        color: $paragraphColor;
        flex-grow: 1;
        font-weight: 500;
        margin: 0;
        margin-right: 15px;
      }
    }

    small {
      color: $smallTextColor;
      display: block;
      margin-top: 20px;
    }
  }
}
