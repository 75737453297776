@import "components/ColorsVars.scss";

.DialogBackdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadein 0.2s ease-in normal 1;

  > .Dialog {
    background-color: white;
    padding: 1.5rem;
    width: 360px;
    max-width: 100%;
    border-radius: 4px;

    > .DialogHeader {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      > .DialogTitle {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        font-size: 1.25rem;
        color: #1a304d;
      }
      > .DialogClose {
        font-size: 2rem;
        height: 1rem;
        line-height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 21px;
        color: #c1cfdc;
        &:hover {
          color: #69798c;
        }
      }
    }
    > .DialogBody {
      margin-top: 1.5rem;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
