@import "../../assets/designSystem";

.Alert {
  display: flex;
  flex-direction: column;

  .layer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.espace-collab {
      .label {
        @include espace-collab-body1-regular;
        color: $surface-light;
      }
    }
    &.espace-rh {
      .label {
        @include espace-rh-body1-regular;
        color: $surface-light;
      }
    }
    &.inline {
      &.success {
        button {
          color: $status-success-darker;
          border: 1px solid $status-success-default;
        }
      }
      &.error {
        button {
          color: $status-error-darker;
          border: 1px solid $status-error-default;
        }
      }
      &.warning {
        button {
          color: $status-warning-darker;
          border: 1px solid $status-warning-default;
        }
      }
    }
  }

  &.full-bleed,
  &.floating {
    background-color: $plainText-onLight-default;
    box-shadow: $elevation-alert;

    .close {
      svg {
        fill: $plainText-onDark-darker;
      }
    }

    .alertButton {
      padding-right: $skui-spacing-3xl;
      padding-bottom: $skui-spacing-s;

      button {
        border: 1px solid $surface-light;
        color: $surface-light;
      }
    }

    .alertButtonLowEmphasis {
      padding-right: $skui-spacing-xs;
      padding-bottom: $skui-spacing-s;
      
      button {
        color: $surface-light;
      }
    }

    .alignButtons {
      height: $skui-spacing-l;
      align-self: center;
      padding-right: $skui-spacing-xs;

      button {
        border: 1px solid $surface-light;
        color: $surface-light;
      }
    }
  }

  &.floating {
    border-radius: 0.25rem;
    width: 25rem;
    margin-top: $skui-spacing-xs;
  }

  &.inline {
    .close {
      svg {
        fill: $plainText-onLight-lighter;
      }
    }

    &.success {
      background-color: $status-success-lightest;
      border-radius: 0.25rem;
      border: 1px solid $status-success-default;
      .label {
        color: $status-success-darker;
      }
    }
    &.info,
    &.info_inline,
    &.timer {
      background-color: $action-onLight-lightest;
      border-radius: 0.25rem;
      border: 1px solid $action-onLight-default;
      .label {
        color: $action-onLight-darkest;
      }
    }
    &.warning {
      background-color: $status-warning-lightest;
      border-radius: 0.25rem;
      border: 1px solid $status-warning-default;
      .label {
        color: $status-warning-darker;
      }
    }
    &.error {
      background-color: $status-error-lightest;
      border-radius: 0.25rem;
      border: 1px solid $status-error-default;
      .label {
        color: $status-error-darker;
      }
        padding-bottom: $skui-spacing-s;
        padding-right: $skui-spacing-s;

    }

    .alignButtons {
      height: $skui-spacing-l;
      align-self: center;
      padding-right: $skui-spacing-xs;
    }
  }

  > .icon {
    display: flex;
    align-self: start;
    padding: $skui-spacing-s;
    &.success {
      color: $status-success-default;
    }
    &.info,
    &.info_inline,
    &.timer {
      color: $action-onLight-default;
    }
    &.warning {
      color: $status-warning-default;
    }
    &.error {
      color: $status-error-default;
    }
  }

  .label {
    flex: 1;
    word-wrap: break-word;
    overflow: hidden;
    padding: $skui-spacing-s;
    padding-left: 0;
  }

  .close {
    padding: $skui-spacing-s;
    padding-left: 0;
    display: flex;
    align-self: start;
    cursor: pointer;
  }
}
